import {
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Button } from "components/shared/Button.js";
import { Form, Formik } from "formik";
import React, { useState } from "react";

import specialFormModel from "./FormModel/specialFormModel.js";

import toast from "react-hot-toast";
import { useReward } from "react-rewards";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";

import { ArrowBack } from "@mui/icons-material";
import { addSpecial, addSpecialImage } from "redux/nativeEcommerceSlice";
import BundleDetails from "./BundleDetails.jsx";
import { generateBundleInitialValues } from "./FormModel/formInitialValues.js";
import { validationSchemaBundle } from "./FormModel/validationSchema.js";
import CustomerBuysForm from "./Forms/CustomerBuysForm.jsx";
import CustomerRecievesForm from "./Forms/CustomerRecievesForm.jsx";
import InfoForm from "./Forms/InfoForm.tsx";
import classes from "./styles.module.css";
import ScheduleForm from "./Forms/ScheduleForm.jsx";

const steps = [
  "Basic Info",
  "Schedule",
  "Customer Buys",
  "Customer Recieves",
  "Confirmation",
];
const { formId, formField } = specialFormModel;

export default function BundleCreate() {
  const dispatch = useAppDispatch();
  const business = useAppSelector((state) => state.business.info);
  const initialValues = generateBundleInitialValues(business.id);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchemaBundle[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const config = {
    angle: 270,
    spread: 360,
    startVelocity: 30,
    elementCount: 100,
    decay: 0.9,
  };

  const { reward, isAnimating } = useReward("topRewardId", "confetti", config);

  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return <InfoForm formField={formField} type="BUNDLE" />;
      case 1:
        return <ScheduleForm formField={formField} />;
      case 2:
        return <CustomerBuysForm formField={formField} />;
      case 3:
        return <CustomerRecievesForm formField={formField} />;
      case 4:
        return (
          <BundleDetails onChange={(stepIndex) => setActiveStep(stepIndex)} />
        );
      default:
        return <div>Not Found</div>;
    }
  }
  function getBundleType(qualifier) {
    if (qualifier.customCollection.filters.length > 0) {
      return "COLLECTION";
    } else if (qualifier.requiredValue) {
      return "TOTAL_PRICE";
    } else if (qualifier.requiredWeight) {
      return "TOTAL_WEIGHT";
    }
  }

  function _handleMapQualifiers(bundleQualifiers, bundleType) {
    return bundleQualifiers.map((qualifier) => {
      const type = getBundleType(qualifier);
      const isCustomCollection = type === "COLLECTION";
      const customCollection =
        type === "COLLECTION"
          ? {
              ...qualifier.customCollection,
              filters: qualifier.customCollection.filters.map((filter) => ({
                ...filter,
                includedBrandIds: filter.includedBrandIds.map(
                  (brand) => brand.key
                ),
                includedProductIds: filter.includedProductIds.map(
                  (product) => product._id
                ),
              })),
            }
          : {};

      return {
        ...qualifier,
        type,
        isCustomCollection,
        customCollection,
        requiredQuantity: qualifier.requiredQuantity || 0,
        requiredValue: qualifier.requiredValue || 0,
        requiredWeight: qualifier.requiredWeight || 0,
      };
    });
  }

  async function _submitForm(values, actions) {
    try {
      const updatedQualifiers = _handleMapQualifiers(values.bundleQualifiers);
      const updatedRewards = values.bundleRewards.map((reward) => ({
        ...reward,
        productId: reward.productId._id,
      }));

      // Use updatedRewards in your application

      const updateData = {
        businessId: business.id,
        priority: values.priority,
        specialType: "BUNDLE",
        name: values.name,
        description: values.description,
        enabled: values.enabled,
        startDate: values.startDate,
        endDate: values.endDate || null,
        schedule: values.schedule,
        tags: values.tags,
        promoCodeEnabled: values.promoCodeEnabled,
        promoCode: values.promoCode,
        promoCodeUsesPerUser: values.promoCodeUsesPerUser,
        maxUsesPerCart: values.maxUsesPerCart !== '' ? values.maxUsesPerCart : null,
        photoUrl: "",
        terms: values.terms,
        menuTypes: values.menuTypes,
        orderTypes: [],
        discounts: [],
        requiredItemsInCart: 0,
        requiredTotalValue: 0,
        requiredTotalWeight: 0,
        bundleQualifiers: updatedQualifiers,
        bundleRewards: updatedRewards,
      };

      // Dispatch the addSpecial action and store the result in a variable
      const resultAction = await dispatch(
        addSpecial({
          special: updateData,
          key: business.menuSettings?.jointEcommercePublicKey,
        })
      );

      // Check if the addSpecial action was fulfilled
      if (addSpecial.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        actions.setSubmitting(false);
        toast.success("Business special added successfully.");

        if (result.id && values.photoFile) {
          const imageResultAction = await dispatch(
            addSpecialImage({
              id: result.id,
              image: values.photoFile,
              key: business.menuSettings?.jointEcommercePublicKey,
            })
          );
          if (addSpecialImage.fulfilled.match(imageResultAction)) {
          } else {
            toast.error("Error uploading special image.");
          }
        }
        reward();
        navigate(`/businesses/${business.id}/ecommerce/specials`);
      } else if (addSpecial.rejected.match(resultAction)) {
        const error = resultAction.error;
        console.error("Error adding business special:", error);
        toast.error("Error adding business special.");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      toast.error("An error occurred. Please try again.");
    }
    actions.setSubmitting(false);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <div className={classes.form}>
      <div>
        <Button
          component={Link}
          to={`/businesses/${business.id}/ecommerce/specials`}
          startIcon={<ArrowBack />}
        >
          Return to specials
        </Button>
      </div>
      <div className={classes.header}>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          paddingBottom={2}
        >
          Let's set up your Bundle
        </Typography>
        <Stepper
          activeStep={activeStep}
          className={classes.stepper}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <React.Fragment>
        {activeStep === steps.length ? (
          <h1>Special Success View</h1>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep)}

                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? "Create Special" : "Next"}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </div>
  );
}
