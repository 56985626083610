import InfoIcon from "@mui/icons-material/Info";
import { Chip, Grid, Skeleton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getBusiness } from "redux/businessSlice";
import { nativeEcommerceService } from "services";
import { getProducts } from "services/productService";
import { Button } from "components/shared";
import { createProductIdSearchData } from "components/businesses/ecommerce/specials/modals/data/productSearchData";

export function SpecialDiscountPreview({
  discount,
  businessId,
  editing,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState(null);
  const [includedProducts, setIncludedProducts] = useState([]);
  const [excludedProducts, setExcludedProducts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const collectionKey = editing ? "collection" : "customCollection";
  const handleCollectionClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const fetchCollection = async () => {
      try {
        setLoading(true); // Set loading to true when starting the fetch
        const businessResponse = await dispatch(getBusiness(businessId));
        const publicKey =
          businessResponse.payload.menuSettings?.jointEcommercePublicKey ||
          "defaultPublicKey";
        const collectionResponse =
          await nativeEcommerceService.getCollectionById(
            discount.collectionId,
            publicKey
          );
        setCollection(collectionResponse);
      } catch (error) {
        console.error("Error fetching collection:", error);
      } finally {
        setLoading(false); // Set loading to false when the fetch is done (whether successful or not)
      }
    };

    const mapProducts = (items) =>
      items.map((item) => (typeof item === "string" ? item : item._id));
    const mapIdsToKeys = (items) =>
      items.map((item) => (typeof item === "string" ? item : item.key));

    if (discount.isCustomCollection) {
      const updatedCollection = {
        ...discount?.[collectionKey],
        filters: discount?.[collectionKey].filters.map((filter) => ({
          ...filter,
          includedProductIds: mapProducts(filter.includedProductIds || []),
          excludedProductIds: mapProducts(filter.excludedProductIds || []),
          includedCategories: mapIdsToKeys(filter.includedCategories || []),
          includedSubCategories: mapIdsToKeys(filter.includedSubCategories || []),
          excludedCategories: mapIdsToKeys(filter.excludedCategories || []),
          includedBrandIds: mapIdsToKeys(filter.includedBrandIds || []),
          excludedBrandIds: mapIdsToKeys(filter.excludedBrandIds || []),
        })),
      };
      setCollection(updatedCollection);
    } else if (!discount.isCustomCollection && discount.collectionId) {
      fetchCollection();
    }
  }, [discount.collectionId]);

  useEffect(() => {
    let active = true;
    if (!loading && collection && collection.filters) {
      const productIds = collection.filters.flatMap((filter) => [
        ...filter.includedProductIds,
        ...filter.excludedProductIds,
      ]);
      const data = createProductIdSearchData(businessId, productIds);

      (async () => {
        try {
          const response = await getProducts(data);
          const allProducts = response?.hits?.hits;
          const includedProducts = allProducts.filter((product) =>
            collection.filters.some((filter) =>
              filter.includedProductIds.includes(product._source.jointId)
            )
          );
          const excludedProducts = allProducts.filter((product) =>
            collection.filters.some((filter) =>
              filter.excludedProductIds.includes(product._source.jointId)
            )
          );
          if (active) {
            setIncludedProducts(includedProducts);
            setExcludedProducts(excludedProducts);
          }
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      })();
    }

    return () => {
      active = false;
    };
  }, [loading, collection, businessId]);

  return (
    <Grid container marginY={1}>
      <Grid item xs={3}>
        <div>
          <Typography variant={"h6"} fontWeight={"bold"}>
            Discount Value
          </Typography>
        </div>
        <Chip
          label={
            discount.type === "PERCENTAGE"
              ? `${discount.value}% off`
              : discount.type === "DOLLAR_AMOUNT"
                ? `$${discount.value} off`
                : discount.type === "TARGET_PRICE"
                  ? `$${discount.value} for item`
                  : null
          }
        />
      </Grid>
      <Grid item xs={3}>
        <div>
          <Typography variant={"h6"} fontWeight={"bold"}>
            Discount Filter
          </Typography>
        </div>
        {loading ? (
          // Show a skeleton while loading
          <Skeleton variant="text" />
        ) : (
          // Show the collection name if available
          <Chip
            label={
              discount.isCustomCollection
                ? "Custom Collection"
                : collection?.name
            }
            icon={<InfoIcon />}
            onClick={handleCollectionClick}
          />
        )}
      </Grid>
      {collection && (
        <Dialog
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            minWidth: "200px",
          }}
        >
          <DialogTitle id="alert-dialog-title" fontWeight={"bold"}>
            {discount.isCustomCollection
              ? "Custom Collection"
              : collection.name}
          </DialogTitle>
          <DialogContent>
            {collection.filters.map((filter, index) => (
              <div key={`filter-${index}`}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  fontWeight={"bold"}
                >
                  Filter {index + 1}:
                </Typography>
                {filter.includedBrandIds &&
                  filter.includedBrandIds.length > 0 && (
                    <Typography variant="body2" gutterBottom>
                      Included Brands: {filter.includedBrandIds.join(", ")}
                    </Typography>
                  )}
                {filter.excludedBrandIds &&
                  filter.excludedBrandIds.length > 0 && (
                    <Typography variant="body2" gutterBottom>
                      Excluded Brands: {filter.excludedBrandIds.join(", ")}
                    </Typography>
                  )}
                {filter.includedCategories &&
                  filter.includedCategories.length > 0 && (
                    <Typography variant="body2" gutterBottom>
                      Included Categories:{" "}
                      {filter.includedCategories
                        .map(category => category.toLowerCase().replace(/_/g, ' ').replace(/(^|\s)\w/g, (c) => c.toUpperCase()))
                        .join(", ")}

                    </Typography>
                  )}
                {filter.includedSubCategories &&
                  filter.includedSubCategories.length > 0 && (
                    <Typography variant="body2" gutterBottom>
                      Included SubCategories:{" "}
                      {filter.includedSubCategories
                        .map(subCategory => subCategory.toLowerCase().replace(/_/g, ' ').replace(/(^|\s)\w/g, (c) => c.toUpperCase()))
                        .join(", ")}
                    </Typography>
                  )}
                {filter.excludedCategories &&
                  filter.excludedCategories.length > 0 && (
                    <Typography variant="body2" gutterBottom>
                      Excluded Categories:{" "}
                      {filter.excludedCategories.join(", ")}
                    </Typography>
                  )}
                {filter.includedProductIds &&
                  filter.includedProductIds.length > 0 && (
                    <Typography variant="body2" gutterBottom>
                      Included Products:{" "}
                      {filter.includedProductIds
                        .map((productId) => {
                          const product = includedProducts.find(
                            (p) => p._source.jointId === productId
                          );
                          return product ? product._source?.name : "Unknown";
                        })
                        .join(", ")}
                    </Typography>
                  )}

                {filter.excludedProductIds &&
                  filter.excludedProductIds.length > 0 && (
                    <Typography variant="body2" gutterBottom>
                      Excluded Products:{" "}
                      {filter.excludedProductIds
                        .map((productId) => {
                          const product = excludedProducts.find(
                            (p) => p._source.jointId === productId
                          );
                          return product ? product._source?.name : "Unknown";
                        })
                        .join(", ")}
                    </Typography>
                  )}
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Grid>
  );
}

export default SpecialDiscountPreview;
