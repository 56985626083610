import {
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { Button } from "components/shared/Button.js";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";

import specialFormModel from "./FormModel/specialFormModel.js";

import toast from "react-hot-toast";
import { useReward } from "react-rewards";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";

import { ArrowBack } from "@mui/icons-material";
import {
  addSpecialImage,
  editSpecial,
} from "redux/nativeEcommerceSlice";
import BundleDetails from "./BundleDetails.jsx";
import { generateBundleEditInitialValues } from "./FormModel/formInitialValues.js";
import { validationSchemaBundle } from "./FormModel/validationSchema.js";
import CustomerBuysForm from "./Forms/CustomerBuysForm.jsx";
import CustomerRecievesForm from "./Forms/CustomerRecievesForm.jsx";
import InfoForm from "./Forms/InfoForm.tsx";
import classes from "./styles.module.css";

import { getProducts } from "services/productService";
import ScheduleForm from "./Forms/ScheduleForm.jsx";
import { createProductSearchData } from "components/businesses/ecommerce/specials/modals/data/productSearchData.js";

const steps = [
  "Basic Info",
  "Schedule",
  "Customer Buys",
  "Customer Recieves",
  "Confirmation",
];
const { formId, formField } = specialFormModel;

const getBundleQualifier = (special, allProducts) => {
  if (special?.bundleQualifiers?.length > 0) {
    const updatedBundleQualifiers = special.bundleQualifiers.map(
      (qualifier) => {
        const {
          customCollection,
          requiredQuantity,
          requiredValue,
          requiredWeight,
        } = qualifier;
        const { filters } = customCollection;

        const updatedFilters = filters.map((filter) => {
          const includedProductIds = filter.includedProductIds.map(
            (productId) => {
              const product = allProducts.find(
                (product) => product._id === productId
              );
              return product || productId; // return the original productId if no matching product was found
            }
          );
          const includedCategories = filter.includedCategories || [];

          const includedBrandIds = filter.includedBrandIds.map((brandId) => {
            return {
              key: brandId,
              doc_count: 0,
            };
          });

          return {
            ...filter,
            includedProductIds,
            includedCategories,
            includedBrandIds,
          };
        });

        return {
          ...qualifier,
          requiredQuantity: requiredQuantity === 0 ? null : requiredQuantity,
          requiredValue: requiredValue === 0 ? null : requiredValue,
          requiredWeight: requiredWeight === 0 ? null : requiredWeight,
          customCollection: { ...customCollection, filters: updatedFilters },
        };
      }
    );

    return updatedBundleQualifiers;
  }
  return special?.bundleQualifiers || [];
};

const getBundleRewards = (special, allProducts) => {
  if (special?.bundleRewards?.length > 0) {
    const updatedBundleRewards = special.bundleRewards.map((reward) => {
      const product = allProducts.find(
        (product) => product._id === reward.productId
      );
      return product ? { ...reward, productId: product } : reward;
    });

    return updatedBundleRewards;
  }
  return special?.bundleRewards || [];
};

export default function BundleEdit({ business, special }) {
  const dispatch = useAppDispatch();

  const [allProducts, setAllProducts] = useState([]);
  const updatedBundleQualifiers = getBundleQualifier(special, allProducts);
  const updatedBundleRewards = getBundleRewards(special, allProducts);
  const updatedSpecial = {
    ...special,
    bundleQualifiers: updatedBundleQualifiers,
    bundleRewards: updatedBundleRewards,
  };
  const initialValues = generateBundleEditInitialValues(
    business.id,
    updatedSpecial
  );
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchemaBundle[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const config = {
    angle: 270,
    spread: 360,
    startVelocity: 30,
    elementCount: 100,
    decay: 0.9,
  };

  const { reward, isAnimating } = useReward("topRewardId", "confetti", config);

  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return <InfoForm formField={formField} type="BUNDLE" editing />;
      case 1:
        return <ScheduleForm formField={formField} editing />;
      case 2:
        return <CustomerBuysForm formField={formField} editing />;
      case 3:
        return <CustomerRecievesForm formField={formField} editing />;
      case 4:
        return (
          <BundleDetails onChange={(stepIndex) => setActiveStep(stepIndex)} />
        );
      default:
        return <div>Not Found</div>;
    }
  }
  function getBundleType(qualifier) {
    if (qualifier.customCollection.filters.length > 0) {
      return "COLLECTION";
    } else if (qualifier.requiredValue) {
      return "TOTAL_PRICE";
    } else if (qualifier.requiredWeight) {
      return "TOTAL_WEIGHT";
    }
  }

  function _handleMapQualifiers(bundleQualifiers, bundleType) {
    return bundleQualifiers.map((qualifier) => {
      const type = getBundleType(qualifier);
      const isCustomCollection = type === "COLLECTION";
      const mapProducts = (items) =>
        items.map((item) => (typeof item === "string" ? item : item._id));
      const mapIdsToKeys = (items) =>
        items.map((item) => (typeof item === "string" ? item : item.key));
      const customCollection = {
        ...qualifier.customCollection,
        filters: qualifier.customCollection.filters.map((filter) => ({
          ...filter,
          includedProductIds: mapProducts(filter.includedProductIds),
          excludedProductIds: mapProducts(filter.excludedProductIds || []),
          includedCategories: mapIdsToKeys(filter.includedCategories),
          excludedCategories: mapIdsToKeys(filter.excludedCategories || []),
          includedBrandIds: mapIdsToKeys(filter.includedBrandIds),
          excludedBrandIds: mapIdsToKeys(filter.excludedBrandIds || []),
        })),
      };

      console.log(isCustomCollection, customCollection)

      return {
        ...qualifier,
        type,
        isCustomCollection: true,
        customCollection,
        requiredQuantity: qualifier.requiredQuantity || 0,
        requiredValue: qualifier.requiredValue || 0,
        requiredWeight: qualifier.requiredWeight || 0,
      };
    });
  }

  async function _submitForm(values, actions) {
    try {
      const updatedQualifiers = _handleMapQualifiers(values.bundleQualifiers);
      const updatedRewards = values.bundleRewards.map((reward) => ({
        ...reward,
        productId: reward.productId ? reward.productId._source.jointId : "",
      }));

      // Use updatedRewards in your application

      const updateData = {
        ...special,
        name: values.name,
        description: values.description,
        enabled: values.enabled,
        priority: values.priority,
        startDate: values.startDate,
        endDate: values.endDate || null,
        tags: values.tags || [],
        schedule: values.schedule,
        promoCodeEnabled: values.promoCodeEnabled,
        promoCode: values.promoCode,
        promoCodeUsesPerUser: values.promoCodeUsesPerUser,
        maxUsesPerCart: values.maxUsesPerCart !== '' ? values.maxUsesPerCart : null,
        photoUrl: "",
        terms: values.terms,
        menuTypes: values.menuTypes,
        bundleQualifiers: updatedQualifiers,
        bundleRewards: updatedRewards,
      };

      // Dispatch the addSpecial action and store the result in a variable
      const resultAction = await dispatch(
        editSpecial({
          specialId: special.id,
          special: updateData,
          key: business.menuSettings?.jointEcommercePublicKey,
        })
      );

      // Check if the addSpecial action was fulfilled
      if (editSpecial.fulfilled.match(resultAction)) {
        const result = resultAction.payload;
        actions.setSubmitting(false);
        toast.success("Business special edited successfully.");

        if (result.id && values.photoFile) {
          const imageResultAction = await dispatch(
            addSpecialImage({
              id: result.id,
              image: values.photoFile,
              key: business.menuSettings?.jointEcommercePublicKey,
            })
          );
          if (addSpecialImage.fulfilled.match(imageResultAction)) {
          } else {
            toast.error("Error uploading special image.");
          }
        }
        reward();
        navigate(`/businesses/${business.id}/ecommerce/specials`);
      } else if (editSpecial.rejected.match(resultAction)) {
        const error = resultAction.error;
        console.error("Error editing business special:", error);
        toast.error("Error editing business special.");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      toast.error("An error occurred. Please try again.");
    }
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  // Get all products for the business
  useEffect(() => {
    const data = createProductSearchData(business.id);

    (async () => {
      try {
        const response = await getProducts(data);
        const products = response?.hits?.hits;
        setAllProducts(products);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    })();
  }, []);

  return (
    <div className={classes.form}>
      <div>
        <Button
          component={Link}
          to={`/businesses/${business.id}/ecommerce/specials`}
          startIcon={<ArrowBack />}
        >
          Return to specials
        </Button>
      </div>
      <div className={classes.header}>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          paddingBottom={2}
        >
          Let's Edit your Bundle
        </Typography>
        <Stepper
          activeStep={activeStep}
          className={classes.stepper}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <React.Fragment>
        {activeStep === steps.length ? (
          <h1>Special Success View</h1>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
            enableReinitialize
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep)}

                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? "Update Special" : "Next"}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </div>
  );
}
