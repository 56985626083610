import { Add, InfoOutlined, Remove } from "@mui/icons-material";
import { Box, Chip, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AlertType } from "types/alertTypes";
import TransitionAlerts from "components/shared/TransitionAlert";
import { useField, useFormikContext } from "formik";
import FilterOptions from "components/businesses/ecommerce/specials/filters/FilterOptions";
import SelectDialog from "../dialogs/select-dialog/select-dialog.component";
import { Button } from "components/shared";
import BrandSelectDialog from "../dialogs/select-dialog/brand-select-dialog.component";
import { createBrandSearchData, createCategorySearchData, createProductSearchData } from "../modals/data/productSearchData";
import { getProducts } from "services/productService";
import ProductSelectDialog from "../dialogs/select-dialog/product-select-dialog";

const FilterForm = ({ businessId, editing, formField: { discounts } }) => {
  const formik = useFormikContext();
  const { values, setFieldValue } = formik;
  const [, discountsMeta, discountHelpers] = useField(discounts.name);
  const [filters, setFilters] = useState([]);
  const [transitionOpen, setTransitionOpen] = useState(false);
  const [activeFilterIndex, setActiveFilterIndex] = useState(null);
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const [brandDialogOpen, setBrandDialogOpen] = useState(false);
  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);



  const handleOpenCategoryDialog = (index) => {
    setActiveFilterIndex(index);
    setCategoryDialogOpen(true);

  };

  const handleCloseCategoryDialog = () => {
    setCategoryDialogOpen(false);
    setActiveFilterIndex(null);
  };

  const handleOpenBrandDialog = (index) => {
    setActiveFilterIndex(index);
    setBrandDialogOpen(true);
  };

  const handleCloseBrandDialog = () => {
    setBrandDialogOpen(false);
    setActiveFilterIndex(null);
  };

  const handleOpenProductDialog = (index) => {
    setActiveFilterIndex(index);
    setProductDialogOpen(true);
  };

  const handleCloseProductDialog = () => {
    setProductDialogOpen(false);
    setActiveFilterIndex(null);
  };

  // Remove filter at current index
  const handleRemoveFilterFromDiscount = (index) => {
    const newFilters = [...formik.values.discounts[0][collectionKey].filters];
    newFilters.splice(index, 1);
    formik.setFieldValue(`discounts[0].${collectionKey}.filters`, newFilters);
  };

  const collectionKey = editing ? "collection" : "customCollection";

  const handleBrandChange = (selectedBrands, filterIndex) => {
    setFieldValue(`discounts[0].${collectionKey}.filters[${filterIndex}].includedBrandIds`, selectedBrands);
  };

  const handleCategoryChange = (selectedCategories, selectedSubCategories, filterIndex, setFieldValue) => {
    setFieldValue(`discounts[0].${collectionKey}.filters[${filterIndex}].includedCategories`, selectedCategories);
    setFieldValue(`discounts[0].${collectionKey}.filters[${filterIndex}].includedSubCategories`, selectedSubCategories);
  };

  const handleProductChange = (selectedProducts, filterIndex) => {
    setFieldValue(`discounts[0].${collectionKey}.filters[${filterIndex}].includedProductIds`, selectedProducts);
  };

  useEffect(() => {
    if (discountsMeta.value && discountsMeta.value[0]?.[collectionKey]?.filters) {
      setFilters(discountsMeta.value[0]?.[collectionKey].filters);
    } else {
      setFilters([]);
    }
  }, [discountsMeta]);

  const fetchData = async () => {
    if (activeFilterIndex !== null) {
      const includedBrandKeys = values.discounts[0]?.[collectionKey]?.filters[activeFilterIndex]?.includedBrandIds.map(brand => brand.key) || [];
      const selectedProductsKeys = values.discounts[0][collectionKey].filters[activeFilterIndex]?.includedProductIds.map(product => product._source.jointId) || [];

      const data = createCategorySearchData(
        businessId,
        includedBrandKeys,
        selectedProductsKeys
      );
      const categoryResponse = await getProducts(data);

      const includedCategories = values.discounts[0][collectionKey].filters[activeFilterIndex]?.includedCategories || [];
      const includedSubCategories = values.discounts[0][collectionKey].filters[activeFilterIndex]?.includedSubCategories || [];

      const brandsData = createBrandSearchData(
        businessId,
        includedCategories,
        includedSubCategories,
        selectedProductsKeys
      );
      const brandsResponse = await getProducts(brandsData);

      const productData = createProductSearchData(businessId, includedBrandKeys, includedCategories, [], []);
      const productResponse = await getProducts(productData);
      const products = productResponse?.hits?.hits || [];

      setProducts(products);

      let categoriesFound = [];
      let commonCategories = [];
      let brandsFound = [];
      let commonBrands = [];

      // Identify common categories
      categoryResponse.aggregations.brands.buckets.forEach((brand) => {
        brand.categories.buckets.forEach((category) => {
          categoriesFound.push(category);
          if (categoriesFound.filter((c) => c.key === category.key).length >= categoryResponse.aggregations.brands.buckets.length) {
            commonCategories.push(category);
          }
        });
      });

      // Identify common brands
      brandsResponse.aggregations.brands.buckets.forEach((brand) => {
        brandsFound.push(brand);
        if (brandsFound.filter((b) => b.key === brand.key).length >= brandsResponse.aggregations.brands.buckets.length) {
          commonBrands.push(brand);
        }
      });

      if (includedBrandKeys.length > 1) {
        setCategories(commonCategories);
      } else {
        setCategories(categoryResponse.aggregations.categories.buckets);
      }

      if (includedCategories.length > 1) {
        setBrands(commonBrands);
      } else {
        setBrands(brandsResponse.aggregations.brands.buckets);
      }

      commonCategories = [];
      categoriesFound = [];
      commonBrands = [];
      brandsFound = [];
    } else {
      // IF there is no active filter fetch all products
      const productData = createProductSearchData(businessId, [], [], [], []);
      const productResponse = await getProducts(productData);
      const products = productResponse?.hits?.hits || [];
      setProducts(products);
    }
  };

  useEffect(() => {
    fetchData();
  }, [activeFilterIndex]);

  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    if (!products || products.length === 0) return;
    const selectedProducts = values.discounts[0][collectionKey].filters.flatMap(filter => filter.includedProductIds || []);

    const missingProducts = selectedProducts.filter(product => !product._source.name);

    // Now you can find the missing names in the array of products
    const filledProducts = missingProducts.map(missingProduct => {
      const foundProduct = products.find(product => product._source.jointId === missingProduct._id);
      return {
        ...missingProduct,
        _source: foundProduct?._source || { name: "Product not found" }
      };
    });

    // Update the state with the filled products
    const updatedFilters = values.discounts[0][collectionKey].filters.map(filter => {
      const includedProductIds = filter.includedProductIds.map(productId => {
        const filledProduct = filledProducts.find(product => product._id === productId._id);
        return filledProduct || productId;
      });
      return {
        ...filter,
        includedProductIds
      };
    });

    const updatedDiscounts = values.discounts.map(discount => ({
      ...discount,
      [collectionKey]: {
        ...discount[collectionKey],
        filters: updatedFilters
      }
    }));

    setFieldValue("discounts", updatedDiscounts);

  }, [products]);





  const AddConditionButton = ({ index }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="outlined"

          sx={{ margin: "16px auto", }}
        >
          Add Condition
        </Button>
        <Menu
          id="add filter items"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => handleOpenBrandDialog(index)}>Items from a Brand</MenuItem>
          <MenuItem onClick={() => handleOpenProductDialog(index)}>Individual Items</MenuItem>
          <MenuItem onClick={() => handleOpenCategoryDialog(index)}>Items from a Category</MenuItem>
        </Menu>
      </div>
    );
  };


  return (
    <div>
      <div style={{ margin: "1rem 0 0" }}>
        <div style={{ display: "flex", alignItems: "center", margin: "1rem 0 0" }}>
          <Typography variant="body1" component="p" style={{ fontWeight: "bold", marginRight: "0.25rem" }}>
            Discount Filters
          </Typography>
          <IconButton onClick={() => setTransitionOpen(!transitionOpen)} size="small" color="info">
            <InfoOutlined fontSize="small" />
          </IconButton>
        </div>
      </div>
      <div>
        <TransitionAlerts
          title="How to Add Filters to Special Offer"
          description="To customize your special offer, select brands, categories, and products to include or exclude. Use the weight filter/value for further refinement. Chain filters with AND/OR operators as needed. Finally, select the discount value. Click 'Save' to apply your filters."
          type={AlertType.Info}
          transitionOpen={transitionOpen}
          setTransitionOpen={setTransitionOpen}
        />
      </div>
      {filters.map((filter, index) => {
        const selectedBrandKeys = values.discounts[0]?.[collectionKey]?.filters[index]?.includedBrandIds.map(brand => brand.key) || [];
        const selectedProducts = values.discounts[0][collectionKey].filters[index]?.includedProductIds || [];
        const selectedCategories = values.discounts[0][collectionKey].filters[index]?.includedCategories || [];
        const selectedSubCategories = values.discounts[0][collectionKey].filters[index]?.includedSubCategories || [];

        return (
          <div key={index}>
            {selectedBrandKeys.length === 0 && selectedCategories.length === 0 && selectedSubCategories.length === 0 && selectedProducts.length === 0 ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
                p={2}
              >
                <Typography variant="subtitle1">
                  This filter has no selected products, brands, or categories yet!
                </Typography>
                <Typography variant="subtitle2">
                  Click the button below to add your first condition.
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                  <AddConditionButton index={index} />
                  {index > 0 && (
                    <Tooltip title="Remove this filter" placement="top">
                      <IconButton
                        color="error"
                        sx={{ backgroundColor: "#ff4d4f15", margin: "8px 0" }}
                        onClick={() => handleRemoveFilterFromDiscount(index)}
                      >
                        <Remove />
                      </IconButton>
                    </Tooltip>
                  )}

                </Box>
              </Box>
            ) :
              <>
                <AddConditionButton index={index} />
                <Box display="flex" gap={2} alignItems="baseline" flexWrap="wrap" mb={2}>
                  {selectedBrandKeys.length > 0 && (
                    <Box flex={1}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="h5" component="p">
                          Brands
                        </Typography>
                        <Button onClick={() => handleOpenBrandDialog(index)}>Edit</Button>
                      </Box>
                      <Box display="flex" justifyContent="start" alignItems="center" flexWrap="wrap" gap={1}>
                        {selectedBrandKeys.slice(0, 5).map((brandId) => (
                          <Box key={brandId} my={1}>
                            <Chip label={brands.find((brand) => brand.key === brandId)?.key || brandId} />
                          </Box>
                        ))}
                        {selectedBrandKeys.length > 5 && (
                          <Box my={1}>
                            <Chip label={`${selectedBrandKeys.length - 5} more...`} />
                          </Box>
                        )}

                      </Box>
                    </Box>
                  )}
                  {selectedCategories.length > 0 && (
                    <Box flex={1} mb={2}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="h5" component="p">
                          Categories
                        </Typography>
                        <Button onClick={() => handleOpenCategoryDialog(index)}>Edit</Button>
                      </Box>
                      <Box display="flex" justifyContent="start" alignItems="center" flexWrap="wrap" gap={1}>
                        {selectedCategories.slice(0, 5).map((category) => (
                          <Box key={category} my={1}>
                            <Chip label={category.toLowerCase().replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase())} />
                          </Box>
                        ))}
                        {selectedCategories.length > 5 && (
                          <Box my={1}>
                            <Chip label={`${selectedCategories.length - 5} more...`} />
                          </Box>
                        )}

                      </Box>
                    </Box>
                  )}
                  {selectedSubCategories.length > 0 && (
                    <Box flex={1} mb={2}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="h5" component="p">
                          Sub Categories
                        </Typography>
                        <Button onClick={() => handleOpenCategoryDialog(index)}>Edit</Button>
                      </Box>
                      <Box display="flex" justifyContent="start" alignItems="center" flexWrap="wrap" gap={1}>
                        {selectedSubCategories.slice(0, 5).map((subCategory) => (
                          <Box key={subCategory} my={1}>
                            <Chip label={subCategory.toLowerCase().replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase())} />
                          </Box>
                        ))}
                        {selectedSubCategories.length > 5 && (
                          <Box my={1}>
                            <Chip label={`${selectedSubCategories.length - 5} more...`} />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                  {selectedProducts.length > 0 && (
                    <Box flex={1}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="h5" component="p">
                          Selected Products
                        </Typography>
                        <Button onClick={() => handleOpenProductDialog(index)}>Edit</Button>
                      </Box>
                      <Box display="flex" justifyContent="start" alignItems="center" flexWrap="wrap" gap={1}>
                        {selectedProducts.slice(0, 5).map((product) => (
                          <Box key={product._id} my={1}>
                            <Chip label={product._source.name} />
                          </Box>
                        ))}
                        {selectedProducts.length > 5 && (
                          <Box my={1}>
                            <Chip label={`${selectedProducts.length - 5} more...`} />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}

                </Box>
                <FilterOptions
                  index={index}
                  filters={filters}
                  filter={filter}
                  editing={editing}
                />
              </>
            }
            <ProductSelectDialog
              products={products}
              selectedProducts={values.discounts[0][collectionKey].filters[index]?.includedProductIds || []}
              filterIndex={index}
              onChange={(selectedProducts) => handleProductChange(selectedProducts, index)}
              open={productDialogOpen && activeFilterIndex === index}
              onClose={handleCloseProductDialog}
            />
            <BrandSelectDialog
              brands={brands}
              selectedBrands={values.discounts[0][collectionKey].filters[index]?.includedBrandIds || []}
              filterIndex={index}
              onChange={(selectedBrands) => handleBrandChange(selectedBrands, index)}
              open={brandDialogOpen && activeFilterIndex === index}
              onClose={handleCloseBrandDialog}
            />
            <SelectDialog
              open={categoryDialogOpen && activeFilterIndex === index}
              onClose={handleCloseCategoryDialog}
              categories={categories}
              selectedCategories={values.discounts[0][collectionKey].filters[index]?.includedCategories || []}
              selectedSubCategories={values.discounts[0][collectionKey].filters[index]?.includedSubCategories || []}
              filterIndex={index}
              onChange={(selectedCategories, selectedSubCategories) =>
                handleCategoryChange(selectedCategories, selectedSubCategories, index, setFieldValue)
              }
            />
          </div>
        )
      })}
    </div>
  );
};

export default FilterForm;
